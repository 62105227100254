@font-face {
    font-family: 'Geometria';
    src: url('Geometria-ExtraBold.woff2') format('woff2'),
        url('Geometria-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('Geometria-Medium.woff2') format('woff2'),
    url('Geometria-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Geometria';
    src: url('Geometria.woff2') format('woff2'),
    url('Geometria.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.woff2') format('woff2'), url('Lato-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.woff2') format('woff2'), url('Lato-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
