*{
  margin: 0;
  padding: 0;
  outline: none !important;
  box-sizing: border-box;
  text-decoration: none !important;
}

html, body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  color: #1E151F;
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
}

body{
  background-color: #fff;
  background-image: url("../img/bg.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: relative;

  @media screen and (max-width: 1023px){
    background: #fff;
    &:before{
      content: '';
      position: absolute;
      background: url("../img/bg-sm.svg") no-repeat center top;
      background-size: contain;
      width: 100%;
      top: 0;
      left: 0;
      height: 32.4rem;
    }
  }
}

html {
  font-size: 0.6944vw;
}

.justify-wrap{
  text-align: justify;
  font-size: 0;
  text-justify: newspaper;
}

.justify-wrap:after{
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  height: 0;
}

[placeholder]:focus::-webkit-input-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus::-moz-placeholder {
  color: transparent !important;
}

[placeholder]:focus:-ms-input-placeholder {
  color: transparent !important;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.container{
  margin: 0 auto;
  box-sizing: border-box;
  min-width: 320px;
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  max-width: 102.4rem;

  @media screen and (max-width: 1023px){
    max-width: 100%;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.wrapper{
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  min-width: 320px;
  min-height: 100%;
}

.modal__box{
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-top: 30px;
  margin-bottom: 30px;
}

.modal__custom{
  position: fixed;
  top: 0;
  height: 100vh;
  z-index: 300;
  text-align: center;
  font-size: 0;
  left: 0;
  width: 100vw;
  background: rgba(#1E151F, 0.8);
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  overflow-x: hidden;
  &[data-show="true"] {
    opacity: 1;
    visibility: visible;
    .modal__box {
      -webkit-transform: translateY(0);
      -moz-transform: translateY(0);
      -ms-transform: translateY(0);
      -o-transform: translateY(0);
      transform: translateY(0);
    }
  }
  &:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-right: -0.35em;
    height: 100%;
  }
}


.modal__box{
  width: 34.2rem;
  background: #E8D9C7;
  border-radius: 2.3rem;
  padding: 5.5rem 4rem;
  position: relative;

  @media screen and (max-width: 1023px){
    width: 26rem;
    padding: 3.5rem 2rem;
  }

  & > * {
    position: relative;
    z-index: 3;
  }
}

.modal__close {
  position: absolute;
  width: 3.1rem;
  height: 3.1rem;
  top: -0.8rem;
  right: -0.9rem;
  cursor: pointer;
  z-index: 10;
  &:hover {
    transform: rotate(180deg);
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.modal__box{
  -webkit-transform: translateY(4rem);
  -moz-transform: translateY(4rem);
  -ms-transform: translateY(4rem);
  -o-transform: translateY(4rem);
  transform: translateY(4rem);
}

.logo {
  width: 22rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3.2rem;

  @media screen and (max-width: 1023px){
    width: 12.9rem;
    margin-bottom: 1.9rem;
  }

  img {
    display: block;
    width: 100%;
  }
}

.sc__subtitle{
  margin-bottom: 0.7rem;
  font-weight: 400;
  font-size: 1.97308rem;
  line-height: 2.4rem;

  @media screen and (max-width: 1023px){
    font-size: 1.16106rem;
    line-height: 1.4rem;
    margin-bottom: 0.4rem;
  }
}

.sc__title{
  font-weight: 800;
  font-size: 2.63493rem;
  text-transform: uppercase;
  line-height: 3.2rem;

  @media screen and (max-width: 1023px){
    font-size: 1.55053rem;
    line-height: 1.9rem;
  }
}

.sc__login {
  .sc__header {
    margin-bottom: 6.8rem;
  }
}

.sc__full {
  .container {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1023px){
      align-items: flex-start;
    }
  }
}

.login__form {
  padding-top: 4rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 1023px){
    padding-top: 2.8rem;
  }
}

.form {
  width: 28rem;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 3.2rem;

  @media screen and (max-width: 1023px){
    width: 100%;
    margin-bottom: 2.2rem;
  }
}

.form__field {
  position: relative;

  &:not(:last-child){
    margin-bottom: 1.6rem;
  }

  &--error {
    input, textarea {
      border-color: #BC0033 !important;

      &::-webkit-input-placeholder {
        color: rgba(#BC0033, 0.5);
      }

      &:-moz-placeholder {
        color: rgba(#BC0033, 0.5);
      }

      &::-moz-placeholder {
        color: rgba(#BC0033, 0.5);
      }

      &:-ms-input-placeholder {
        color: rgba(#BC0033, 0.5);
      }
    }
  }

  input, textarea {
    height: 4rem;
    width: 100%;
    display: block;
    padding: 0 1.1rem;
    border: 1px solid #1E151F;
    border-radius: 8px;
    color: #1E151F;
    font-size: 1.6rem;
    line-height: 2rem;

    &[disabled]{
      opacity: 0.65;
      background-color: #fff;
      border: 1px solid rgba(#1E151F, 0.5);
    }

    @media screen and (max-width: 1023px){
      height: 3.8rem;
      font-size: 1.2rem;
    }

    &::-webkit-input-placeholder {
      color: rgba(#1E151F, 0.5);

      @media screen and (max-width: 1023px){
        color: #1E151F;
      }
    }

    &:-moz-placeholder {
      color: rgba(#1E151F, 0.5);

      @media screen and (max-width: 1023px){
        color: #1E151F;
      }
    }

    &::-moz-placeholder {
      color: rgba(#1E151F, 0.5);

      @media screen and (max-width: 1023px){
        color: #1E151F;
      }
    }

    &:-ms-input-placeholder {
      color: rgba(#1E151F, 0.5);

      @media screen and (max-width: 1023px){
        color: #1E151F;
      }
    }
  }

  textarea {
    height: 8rem;
    resize: none;
    padding: 0.8rem 1.1rem;
  }
}

.btn {
  cursor: pointer;
  height: 4.5rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0 2.4rem;
  background: #BC0033;
  color: #EEE7E1 !important;
  letter-spacing: 0.04em;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 94.9%;
  border-radius: 2.25rem;

  @media screen and (max-width: 1023px){
    height: 4rem;
    font-size: 1.2rem;
  }

  &:hover {
    opacity: 0.75;
  }

  &--border {
    border: 1px solid #BC0033;
    background: #fff;
    color: #BC0033 !important;

    &:hover {
      color: #1E151F !important;
      border-color: #1E151F;
    }
  }

  &--small {
    height: 3.6rem;
    font-size: 1.1rem;
    padding: 0 1.2rem;
  }
}

.form--after {
  margin-top: 1.4rem;
}

.form__action, .not__list{
  position: relative;
  &.disabled, &.loading{
    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
    }
    .btn, .not__it{
      opacity: 0.3;
    }
    &:after{
      content: '';
      position: absolute;
      background: url("../img/loader.svg") no-repeat center;
      background-size: contain;
      width: 8.4rem;
      height: 8.4rem;
      left: 50%;
      top: 50%;
      margin-left: -4.2rem;
      margin-top: -4.2rem;
    }
  }
}

.form__action {
  position: relative;
  &.blocked{
    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      z-index: 10;
    }
    .btn{
      opacity: 0.3;
    }
  }
}

.not__list {
  &.disabled {
    &:after{
      background: url("../img/loader-dark.svg") no-repeat center;
      background-size: contain;
    }
  }
}

.error_message {
  color: #BC0033;
  font-size: 1.3rem;
  left: 0;
  margin-top: 0.4rem;

  @media screen and (max-width: 1023px){
    font-size: 1.15rem;
  }
}

.header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 7.4rem;

  @media screen and (max-width: 1023px){
    height: 5.6rem;
  }

  .container {
    height: 100%;

    @media screen and (max-width: 1023px){
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 2.61351rem;
    line-height: 3.3rem;
    color: #1E151F !important;

    @media screen and (max-width: 1023px){
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    &:hover {
      opacity: 0.75;
    }
  }
}

.faq__link {
  width: 5.2rem;
  height: 5.1rem;
  min-width: 5.2rem;
  margin-right: 4rem;

  @media screen and (max-width: 1023px){
    width: 2.4rem;
    height: 2.4rem;
    min-width: 2.4rem;
    margin-right: 1.8rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:hover {
    path {
      &:nth-child(1){
        stroke: #BC0033;
      }
      &:nth-child(2){
        fill: #BC0033;
      }
    }
  }
}

.logout__link {
  width: 3.8rem;
  height: 4.8rem;
  min-width: 3.8rem;
  cursor: pointer;

  @media screen and (max-width: 1023px){
    width: 1.6rem;
    height: 2.4rem;
    min-width: 1.6rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }

  &:hover {
    path {
      &:nth-child(1){
        stroke: #BC0033;
      }
      &:nth-child(2){
        fill: #BC0033;
      }
    }
  }
}

.sc__lk {
  .container {
    padding-top: 2.4rem;

    @media screen and (max-width: 1023px) {
      padding-top: 0;
    }
  }
}

.content--lk {
  padding-top: 10rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 1023px) {
    padding-top: 5.6rem;
    padding-bottom: 0;
  }
}

.lk__box {
  &:not(:last-child){
    margin-bottom: 3rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: 2.5rem;
    }
  }

  & > img {
    display: block;
    width: 100%;
  }

  &--profile {
    background: #FFFFFF;
    border: 0.156973rem solid #EEE7E1;
    border-radius: 2.8rem;
    padding: 3.8rem 4.3rem;

    @media screen and (max-width: 1023px) {
      padding: 0;
      border: none;
      border-radius: 0;
      background: transparent;
      flex-wrap: wrap;
    }
  }
}

.not__it--marked {
  cursor: pointer;
  &:hover {
    &:after {
      background: #BC0033;
    }
  }
}

.azs__numb {
  padding: 0 0.8rem;
  height: 4.4rem;
  background: #BC0033;
  border-radius: 0.866778rem;
  font-weight: 500;
  color: #fff;
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: 'Geometria';
  margin-right: 5rem;

  @media screen and (max-width: 1023px) {
    margin-bottom: 1.7rem;
    margin-right: 0;
    font-size: 1.1rem;
    line-height: 1.4rem;
    height: 3rem;
  }
}

.profile__data-wrap {
  position: relative;

  @media screen and (max-width: 1023px) {
    width: 100%;
  }
}

.profile__data-det {
  position: absolute;
  left: 0;
  top: 100%;
  white-space: nowrap;

  @media screen and (max-width: 1023px) {
    position: relative;
    top: 0;
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.profile__data-det-it {
  font-style: italic;
  font-size: 1.3rem;

   a{
     color: #000 !important;
     border-bottom: 1px solid rgba(#000, 0.5);

     &:hover {
       border-bottom: 1px solid transparent;
     }
   }
}

.profile__name {
  font-weight: 800;
  font-size: 2.2rem;
  line-height: 2.8rem;
  font-family: 'Geometria';

  @media screen and (max-width: 1023px) {
    width: 100%;
    font-size: 1.73271rem;
    line-height: 2.2rem;
    margin-bottom: 0.9rem;
  }
}

.profile__data {
  font-size: 1.8rem;
  line-height: 2.3rem;
  position: relative;
  top: 0.15rem;

  @media screen and (max-width: 1023px) {
    font-size: 1.4rem;
    line-height: 1.8rem;
    top: 0;
  }

  a {
    color: #BC0033 !important;
    border-bottom: 1px solid #BC0033;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.profile__data-box {
  margin-left: auto;

  @media screen and (max-width: 1023px) {
    width: 100%;
    flex-wrap: wrap;

    .profile__data {
      width: 100%;
    }
  }

  .profile__data {
    &:not(:last-child){
      margin-right: 6rem;

      @media screen and (max-width: 1023px) {
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }
  }
}

.profile__data--address {
  max-width: 19rem;

  @media screen and (max-width: 1023px) {
    max-width: 100%;
  }
}

.lk__box--nav {
  background: #E0CAB4;
  border-radius: 2.8rem;
  padding: 1.3rem 0;

  @media screen and (max-width: 1023px) {
    margin-right: -2rem;
    margin-left: -2rem;
    border-radius: 2.8rem 2.8rem 0 0;
    flex-wrap: wrap;
    padding: 3rem 2rem 0;
  }
}

.lk__nav-it {
  width: 25%;
  min-width: 25%;

  @media screen and (max-width: 1023px) {
    width: 50%;
    min-width: 50%;

    &:not(:nth-last-child(1)):not(:nth-last-child(2)){
      border-bottom: 1px solid #6E615E;
    }

    &:nth-child(2n) {
      border-right: none !important;
    }
  }

  &-img {
    height: 9.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-title {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: #6E615E;
    text-align: center;
    min-height: 3.6rem;

    @media screen and (max-width: 1023px) {
      font-size: 1.2rem;
      line-height: 1.3rem;
      min-height: 0;
      padding-bottom: 1.6rem;
    }
  }
}

.lk__nav-it-icon {
  svg {
    width: 100%;
    height: 100%;
    display: block;
  }
}

.lk__nav-it--stats {
  .lk__nav-it-icon {
    width: 5.3rem;
    height: 5rem;
  }
}

.lk__nav-it--cups {
  .lk__nav-it-icon {
    width: 6.8rem;
    height: 4.6rem;
  }
}

.lk__nav-it--not {
  .lk__nav-it-icon {
    width: 4.9rem;
    height: 5.6rem;
    transform: rotate(17.04deg);
  }
}

.lk__nav-it--invite {
  .lk__nav-it-icon {
    width: 5.9rem;
    height: 4.8rem;
  }
}

.lk__nav-it--bot {
  .lk__nav-it-icon {
    width: 6.3rem;
    height: 5.5rem;
  }
}

.lk__nav-it--prizes {
  .lk__nav-it-icon {
    width: 5.9rem;
    height: 5.9rem;
    transform: rotate(-5.02deg);
  }
}

.lk__nav-it--docs {
  .lk__nav-it-icon {
    width: 4.6rem;
    height: 6rem;
    transform: rotate(-12.08deg);
  }
}

.lk__nav-it--rating {
  .lk__nav-it-icon {
    width: 5.2rem;
    height: 4.9rem;
    transform: rotate(12.5deg);
  }
}

.lk__nav-it {
  &:not(:last-child){
    border-right: 1px solid #6E615E;
  }

  &:hover {
    path, rect, circle:not(.no-hover) {
      fill: #FFFFFF;
    }
  }
}

.lk__nav-it {
  cursor: pointer;
}

.back__link {
  i {
    margin-right: 0.3rem;
  }

  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #1E151F !important;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
  }

  &:hover {
    opacity: 0.75;
  }
}

.icon__back {
  width: 1rem;
  height: 0.8rem;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.back__wrap {
  margin-bottom: 2.5rem;

  @media screen and (max-width: 1023px){
    margin-bottom: 2rem;
  }
}

.not__box {
  width: 51.4rem;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1023px){
    width: 100%;
  }
}

.sc__box-title {
  font-weight: 800;
  font-size: 3.2rem;
  line-height: 4rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 1023px) {
    font-size: 1.73271rem;
    line-height: 2.2rem;
    margin-bottom: 0.8rem;
  }
}

.not__it {
  &:not(:last-child){
    margin-bottom: 2rem;

    @media screen and (max-width: 1023px){
      margin-bottom: 0.8rem;
    }
  }

  background: rgba(#FFFFFF, 0.78);
  border: 0.156973rem solid #EEE7E1;
  border-radius: 2.2rem;
  position: relative;
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 2rem 3rem;
  font-family: 'Geometria';
  font-weight: 400;

  @media screen and (max-width: 1023px){
    border-radius: 1.2rem;
    background: rgba(#FFFFFF, 0.9);
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding: 1.2rem 2rem;
  }

  &--important {
    background: #000000;
    color: #fff;
    border-color: #000;
  }

  &--marked {
    position: relative;
    &:after {
      content: '';
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background: #50352A;
      position: absolute;
      top: 50%;
      margin-top: -1rem;
      left: -1rem;

      @media screen and (max-width: 1023px){
        width: 1.6rem;
        height: 1.6rem;
        left: -0.8rem;

      }
    }
  }
}

.lk__nav-it--op {
  width: 20%;
  min-width: 20%;

  @media screen and (max-width: 1023px) {
    width: 50%;
    min-width: 50%;

    &:nth-child(4){
      border-bottom: 1px solid #6E615E;
    }

    &:nth-child(5){
      border-right: 1px solid #6E615E;
    }
  }
}

.sc__docs {
  .sc__box-title {
    margin-bottom: 1.5rem;
  }

  position: relative;

  @media screen and (max-width: 1023px) {
    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -5.6rem;
      background: url("../img/bg-sm-2.svg") no-repeat center top;
      background-size: contain;
      height: 49.1rem;
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }
}


.sc__box-descr {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 3rem;

  @media screen and (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.3rem;
    margin-bottom: 2.5rem;

    br{
      display: none;
    }
  }
}

.lk__box--docs {
  padding: 4.3rem 4rem 4.9rem;
  background: #FFFFFF;
  border: 0.156973rem solid #EEE7E1;
  border-radius: 2.8rem;

  @media screen and (max-width: 1023px) {
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 2.4rem 2rem 3.4rem;
    border: none;
    border-radius: 2.8rem 2.8rem 0 0;
  }
}

.docs__form-right {
  width: 30.7rem;
  min-width: 30.7rem;
  margin-left: 5rem;

  @media screen and (max-width: 1023px) {
    width: 100%;
    min-width: 100%;
    margin-left: 0;
    margin-top: 3.6rem;
  }
}

.docs__form-left{
  width: 100%;
}

.docs__form-title {
  padding-bottom: 1.2rem;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 3.6rem;
  line-height: 2.2rem;
  border-bottom: 1px solid #BEAD9D;

  @media screen and (max-width: 1023px) {
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 2rem;
  }
}

.form__list {
  .row {
    margin-left: -1.2rem;
    margin-right: -1.2rem;
    margin-bottom: -2.6rem;
  }
}

.form__col {
  padding-right: 1.2rem;
  padding-left: 1.2rem;
  margin-bottom: 2.6rem;
}

.form__label {
  margin-bottom: 0.9rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
}

.docs__form {
  margin-bottom: 3.5rem;

  @media screen and (max-width: 1023px) {
    flex-wrap: wrap;
    margin-bottom: 3rem;
  }

  & + .checkbox__list {
    margin-bottom: 3.5rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: 3rem;
    }
  }
}

.chart__box {
  position: relative;
}

.chart__box-lbl {
  position: absolute;
  font-size: 1.4rem;
  line-height: 100%;
  font-family: 'Montserrat';
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
  }

  &--y {
    top: -3rem;
    left: -6rem;

    @media screen and (max-width: 1023px){
      top: -0.3rem;
      left: 2rem;
    }
  }

  &--x {
    right: -5rem;
    bottom: -0.5rem;

    @media screen and (max-width: 1023px){
      right: 0;
      bottom: -2.4rem;
    }
  }
}

.bank-col {
  margin-bottom: 3rem;

  @media screen and (max-width: 1023px){
    flex: 0 0 100%;
    max-width: 100%;
    margin-bottom: 2.6rem;
  }
}

.link__edit {
  img {
    display: block;
    width: 100%;
  }

  width: 2.8rem;
  min-width: 2.8rem;
  margin-left: 1rem;
  cursor: pointer;

  @media screen and (max-width: 1023px){
    width: 2.4rem;
    min-width: 2.4rem;
    margin-left: 0.6rem;
  }

  &:hover{
    opacity: 0.75;
  }
}

.form__it {
  width: 6.6rem;
  min-width: 5.6rem;
  margin-right: 2rem;
  input {
    text-align: center;
  }
}

.file__btn {
  position: relative;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 0.735559rem;
    object-fit: cover;
    display: block;
    opacity: 0.2;
  }
}

.form__field--small{
  input {
    max-width: 11.8rem;
  }
}

.file__it {
  &:not(:last-child){
    margin-right: 1rem;
  }

  label {
    margin: 0;
    position: relative;
    input {
      opacity: 0;
      position: absolute;
      visibility: hidden;
    }
  }
}

.form__eq {
  min-height: 9.3rem;
  margin-bottom: 2.6rem;

  @media screen and (max-width: 1023px){
    min-height: 0;
  }
}

.file__btn {
  width: 6.4rem;
  height: 6.4rem;
  border: 0.0919449rem solid #1E151F;
  border-radius: 0.735559rem;
  position: relative;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  &:after {
    content: '';
    position: absolute;
    background: url("../img/plus.svg") no-repeat center;
    background-size: contain;
    width: 2.7rem;
    height: 2.7rem;
    left: 50%;
    top: 50%;
    margin-left: -1.35rem;
    margin-top: -1.35rem;
  }
}

.invite__box {
  padding-top: 5rem;
  padding-bottom: 5rem;

  @media screen and (max-width: 1023px){
    padding-top: 2.4rem;
    padding-bottom: 4rem;
  }
}

.invite__box-header {
  margin-bottom: 3.5rem;

  @media screen and (max-width: 1023px){
    margin-bottom: 2.6rem;
  }

  .logo {
    width: 13.4rem;
    min-width: 13.4rem;
    margin-right: 2.8rem;
    margin-bottom: 0;

    @media screen and (max-width: 1023px){
      width: 7.1rem;
      min-width: 7.1rem;
      margin-right: 1.5rem;
    }
  }

  .sc__header {
    margin-bottom: 0;
  }

  .sc__title {
    font-size: 2rem;
    line-height: 2.4rem;

    @media screen and (max-width: 1023px){
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  .sc__subtitle {
    font-size: 1.89759rem;
    line-height: 2.3rem;

    @media screen and (max-width: 1023px){
      font-size: 1rem;
      margin-bottom: 0.2rem;
      line-height: 1.2rem;
    }
  }
}

.invite__title {
  margin-bottom: 3.5rem;
  font-size: 1.6rem;
  line-height: 2rem;

  @media screen and (max-width: 1023px){
    text-align: left !important;
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 2.2rem;
  }
}

.checkbox__wrap {
  label {
    position: relative;
    margin-bottom: 0;
    font-weight: normal;
    display: flex;
    align-items: center;

    input {
      position: absolute;
      opacity: 0;
      visibility: hidden;

      &:checked {
        & ~ .checkbox__decor {
          background: #BC0033;
          &:before{
            background: #E8D9C7;
            left: 1.4rem;
          }
        }
      }
    }
  }
}

.checkbox__decor {
  width: 2.8rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  min-width: 2.8rem;
  background: #E8D9C7;
  margin-right: 0.5rem;
  position: relative;
  cursor: pointer;

  &:before{
    content: '';
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    background: #BC0033;
    border-radius: 0.75rem;
    left: 0;
    top: 0;
  }
}

.checkbox__item {
  &:not(:last-child){
    margin-bottom: 1.2rem;
  }
}

.checkbox__title{
  font-size: 1.1rem;
  line-height: 1.2rem;
  cursor: pointer;

  a {
    color: #1E151F !important;
    border-bottom: 1px solid #1E151F;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }
}

.modal__txt {
  margin-bottom: 1.5rem;
  text-align: center;
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: #000000;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.modal__title {
  font-size: 2.2rem;
  font-family: 'Geometria';
  line-height: 2.8rem;
  text-align: center;
  margin-bottom: 1.2rem;
  font-weight: 800;

  @media screen and (max-width: 1023px){
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

.sc__faq {
  .sc__box-title {
    margin-bottom: 3rem;
  }
}

.faq__box {
  background: #FFFFFF;
  border: 0.156973rem solid #EEE7E1;
  border-radius: 2.8rem;
  padding: 4rem 4.5rem 6.5rem;

  @media screen and (max-width: 1023px){
    margin-right: -2rem;
    margin-left: -2rem;
    border: none;
    padding: 2.4rem 2rem 4rem;
    flex-wrap: wrap;
  }
}

.faq__box-left {
  width: 47.5rem;
  min-width: 47.5rem;
  margin-right: 5.5rem;

  @media screen and (max-width: 1023px){
    width: 100%;
    min-width: 100%;
    margin-right: 0;
    margin-bottom: 4.8rem;
  }
}

.faq__box-right {
  width: 100%;

  textarea {
    height: 10.5rem;
  }
}

.faq__it-header {
  cursor: pointer;

  &:hover {
    .faq__it-title {
      color: #BC0033;
    }
  }
}

.faq__it-title {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2rem;
  max-width: 32rem;
  color: #1E151F;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.form__gr {
  &:not(:last-child){
    margin-bottom: 2.5rem;

    @media screen and (max-width: 1023px){
      margin-bottom: 1.6rem;
    }
  }
}

.faq__it-icon {
  width: 1.8rem;
  height: 1.8rem;
  min-width: 1.8rem;
  margin-left: 2rem;

  @media screen and (max-width: 1023px){
    width: 1.2rem;
    height: 1.2rem;
    min-width: 1.2rem;
    position: relative;
    top: 0.2rem;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.faq__it-answer {
  display: none;
}

.faq__it{
  &.active {
    .faq__it-answer {
      display: block;
    }

    .faq__it-icon {
      path {
        &:first-child {
          opacity: 0;
        }
      }
    }
  }
}

.faq__it-txt {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;
  color: #1E151F;
  margin-top: 0.9rem;
  max-width: 90%;

  a {
    color: #1E151F !important;
    border-bottom: 1px solid #1E151F;

    &:hover {
      border-bottom: 1px solid transparent;
    }
  }

  td, th {
    padding: 0.4rem 1rem;
    font-size: 1.3rem;
    line-height: 1.7rem;

    @media screen and (max-width: 1023px){
      font-size: 1rem;
      line-height: 1.2rem;
    }
  }

  table {
    margin: 2rem 0;
  }

  table, td, th, tr {
    border-collapse: collapse;
    border: 1px solid rgba(#1E151F, 0.5);
  }

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.faq__it {
  &:not(:last-child){
    margin-bottom: 4rem;

    @media screen and (max-width: 1023px){
      margin-bottom: 3rem;
    }
  }
}

.select__custom{
  position: relative;
  max-width: 28rem;
}

.select__custom-dropdown {
  position: absolute;
  top: 100%;
  margin-top: 0.2rem;
  left: 0;
  z-index: 3;
  background: #fff;
  width: 100%;
  border-radius: 0.8rem;
  border: 1px solid #1E151F;
  padding: 0.3rem 0;
  opacity: 0;
  visibility: hidden;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
  }

  &.active{
    opacity: 1;
    visibility: visible;
  }
}

.opened {
  .icon__toggle {
    transform: rotateX(180deg);
  }
}

.select__custom-dropdown-item {
  cursor: pointer;
  padding: 0.3rem 1.1rem;

  &:hover, &.active {
    color: #BC0033;
  }
}

.select__custom-input {
  position: relative;
  border: 1px solid #1E151F;
  height: 4rem;
  padding-left: 1.1rem;
  padding-right: 0.7rem;
  border-radius: 0.8rem;
  cursor: pointer;
  align-items: center;
  background-color: #fff;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
    height: 3.8rem;
  }
}

.icon__toggle {
  width: 1.8rem;
  height: 1rem;
  display: block;

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.attach__field {
  label {
    position: relative;
    margin: 0;
    input[type="file"]{
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }
  }
}

.attach__field {
  width: 29rem;
}

.attach__btn {
  width: 4rem;
  min-width: 4rem;
  height: 4rem;
  margin-left: 0.7rem;
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.attach__field-input {
  height: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border: 1px solid #1E151F;
  border-radius: 0.8rem;
  padding: 0 1.1rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: rgba(#1E151F, 0.5);
  cursor: pointer;
  width: 24rem;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
  }
}

.prizes__box {
  max-width: 55.5rem;
  margin-left: auto;
  margin-right: auto;

  .sc__box-title {
    margin-bottom: 1.5rem;
  }
}

.prizes__fl {
  margin-bottom: 3rem;

  @media screen and (max-width: 1023px){
    margin-bottom: 2.5rem;
  }

  &-btn {
    height: 3.8rem;
    border: 1px solid #000000;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    line-height: 1.7rem;
    cursor: pointer;
    padding: 0 2rem;

    @media screen and (max-width: 1023px){
      font-size: 1.1rem;
      padding: 0 1rem;
      height: 3.2rem;
      border-radius: 1.6rem;
    }

    &:hover {
      color: #BC0033;
      border-color: #BC0033;
    }

    &.active {
      background-color: #000;
      border-color: #000 !important;
      color: #fff;
      cursor: default;
    }

    &:not(:last-child){
      margin-right: 0.8rem;
    }
  }
}

.prizes__list {
  padding-right: 6rem;
  padding-left: 5rem;

  @media screen and (max-width: 1023px){
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

.prizes__list-box {
  background: #FFFFFF;
  border: 0.156973rem solid #EEE7E1;
  border-radius: 2.8rem;
  padding: 4rem 2.3rem 4rem 0;

  @media screen and (max-width: 1023px){
    border-radius: 2.8rem 2.8rem 0 0;
    border: none;
    min-height: calc(100vh - 13rem);
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 3rem 0;
  }
}

.prizes__it {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid #BEAD9D;
}

.prizes__it-date {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 0.3rem;
  font-family: 'Geometria';
  color: #9F908C;

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
    line-height: 1.5rem;
    margin-bottom: 0.3rem;
  }
}

.prizes__it-txt {
  font-size: 1.6rem;
  line-height: 2rem;
  color: #1E151F;
  font-family: 'Geometria';

  @media screen and (max-width: 1023px){
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

.rating {
  &__box {
    background: #FFFFFF;
    border: 0.156973rem solid #EEE7E1;
    border-radius: 2.8rem;
    padding: 2rem 0 3.2rem;

    @media screen and (max-width: 1023px){
      margin-left: -2rem;
      margin-right: -2rem;
      border: none;
    }

    &-marker{
      &-circle {
        width: 2.8rem;
        height: 2.8rem;
        border-radius: 50%;
        background: #BC0033;
        margin-right: 1rem;
        min-width: 2.8rem;
      }

      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-bottom: 1.1rem;

      @media screen and (max-width: 1023px){
        margin-right: auto;
        justify-content: flex-start !important;
        margin-left: 0;
        margin-bottom: 4.8rem;
        padding-left: 2rem;
      }
    }

    &-table {
      table {
        border-collapse: collapse;
        width: 100%;
        text-align: center;

        th {
          font-weight: 700;
          font-size: 1.2rem;
          line-height: 1.4rem;
          padding-bottom: 2.9rem;
          vertical-align: bottom;

          @media screen and (max-width: 1023px){
            vertical-align: top;
            padding-bottom: 1.9rem;
          }

          &:nth-child(1) {
            padding-left: 1.4rem;

            @media screen and (max-width: 1023px) {
              padding-left: 0 !important;
            }
          }

          &:last-child {
            padding-right: 1.4rem;
          }
        }

        tbody {
          tr{
            &:nth-child(2n + 1){
              background: #E0CAB4;
            }

            &.tr--bolder {
              font-weight: 700;

              .td__numb {
                background: #BC0033;
                color: #fff;
              }

              td {
                font-weight: 700;
              }
            }

            &.tr--my {
              background: #BC0033;
              color: #fff;
            }
          }
        }

        td {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 1.6rem;
          padding-top: 0.9rem;
          padding-bottom: 0.9rem;

          &:nth-child(1) {
            padding-left: 1.4rem;

            @media screen and (max-width: 1023px) {
              padding-left: 0 !important;
            }
          }

          &:last-child {
            padding-right: 1.4rem;
          }
        }
      }
    }
  }
}

.lk__nav-it--disabled {
  opacity: 0.6;
}

.rating__box-table--my {
  th {
    padding: 0;
  }
}

.rating__box-table--my {
  margin-bottom: 2rem;
}

.td__numb {
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  margin-right: auto;
  margin-left: auto;
  min-width: 4.2rem;
}

.sc__rating {
  .sc__box-title {
    margin-bottom: 1.5rem;

    @media screen and (max-width: 1023px) {
      margin-bottom: 0.7rem;
    }
  }
}

.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
}

.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}

.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}

.rcs-custom-scrollbar {
  opacity: 1 !important;
}

.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}

.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.rcs-custom-scroll .rcs-inner-container:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}

.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}

.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 0.3rem;
  right: 0;
  opacity: 1;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 0;
  box-sizing: border-box;
  will-change: opacity;
  background-color: #555353;
  border-radius: 0.2rem;
  pointer-events: none;

  @media screen and (max-width: 1023px){
    width: 0.3rem;
    right: 0.1rem;
  }
}

.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}

.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}

.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: #BC0033;
  border-radius: 0;
}

.prizes__list-scroll{
  height: 36.6rem;

  @media screen and (max-width: 1023px){
    height: auto;
  }
}

.prizes__it--prize {
  background-color: rgba(#E8D9C7, 0.5);
  position: relative;

  &:after {
    background-color: rgba(#E8D9C7, 0.5);
    position: absolute;
    content: '';
    right: 100%;
    top: 0;
    bottom: -1px;
    width: 5rem;
  }
}

.prizes__it--email {
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  .prizes__it-txt {
    color: #BC0033;
    font-weight: 700;
  }
}

.stat__box {
  max-width: 66.4rem;
  margin-left: auto;
  margin-right: auto;
}

.stat__box-bl {
  padding: 4.4rem 8rem;
  background: #FFFFFF;
  border: 0.156973rem solid #EEE7E1;
  border-radius: 2.8rem;

  @media screen and (max-width: 1023px){
    padding: 2.4rem 2rem;
    border-radius: 2.8rem 2.8rem 0 0;
    border: none;
    margin-right: -2rem;
    margin-left: -2rem;
    min-height: calc(100vh - 12rem);
  }

  .form__field {
    margin-bottom: 2.2rem;
  }
}

.mode__sw {
  padding-left: 1rem;
  margin-bottom: 1.3rem;
}

.mode__it {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 2rem;
  cursor: pointer;
  color: #C6B7A9;

  &:hover,
  &.active {
    color: #1E151F;
  }
}

.mode__toggle {
  position: relative;
  width: 3.9rem;
  height: 2.1rem;
  background: #E8D9C7;
  border-radius: 2rem;
  cursor: pointer;
  margin-right: 0.8rem;
  margin-left: 0.8rem;

  &.active {
    &:before {
      left: 1.9rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 2.1rem;
    height: 2.1rem;
    background: #BC0033;
    border-radius: 50%;
  }
}

.calendar__box {
  margin-bottom: 2rem;

  .react-calendar__tile:disabled {
    background-color: #E8D9C7;
  }

  .react-calendar__tile {
    font-weight: 700;
    padding: 0.6rem 0.2rem;
    border-radius: 1.33793rem;
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
  }

  .react-calendar {
    width: 100%;
    max-width: 100%;
    background: #E8D9C7;
    border: 1px solid #E8D9C7;
    font-family: 'Lato';
    padding: 0 1rem;
    overflow: hidden;
    border-radius: 1.33793rem;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none !important;
  }

  .react-calendar__navigation {
    height: 4rem;
    margin-bottom: 0;
    border-radius: 1.33793rem 1.33793rem 0 0;
    background: #E8D9C7;
  }

  .react-calendar__navigation button {
    background: #E8D9C7 !important;
  }

  .react-calendar__month-view__days {
    padding-bottom: 1rem;
  }

  .react-calendar__tile--now {
    background: rgba(#000, 0.2) !important;
  }

  .react-calendar__tile--active {
    background: #EA6B6B !important;
    color: white;
  }

  .react-calendar__tile:disabled {
    color: rgba(#69695D, 0.5) !important;
    background: transparent !important;
    cursor: default !important;
  }
}

.chart__info-it {
  margin-top: 1.5rem;
  padding-left: 1.1rem;

  span {
    width: 2rem;
    height: 0.2rem;
    min-width: 2rem;
    margin-right: 0.5rem;

    background-color: #1E151F;

    &.chart--red {
      background-color: #BC0033;
    }
  }
}

.chart__info-it-title {
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 100%;
  font-family: 'Montserrat';
  text-align: center;
  letter-spacing: -0.02em;
  color: #000000;
}

.chart__box {
  border-bottom: 1px solid #777777;
  border-left: 1px solid #777777;
  padding-top: 2rem;
  margin-top: 2rem;
}

.loader__img{
  width: 9.6rem;
  margin: 0 auto;
  display: block;
}

.sign__box {
  border: 1px solid rgba(30, 21, 31, 0.5);
  border-radius: 8px;
  width: 40rem;
  overflow: hidden;
  height: 20rem;
  position: relative;
  margin-bottom: 1rem;

  @media screen and (max-width: 1023px){
    width: 100%;
    height: 14rem;
  }

  &--error {
    border: 1px solid #BC0033;
  }

  & > div {
    height: 100%;
    width: 100%;
  }

  canvas {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
}

a, input, button, textarea, .btn__custom, .modal__box, .modal__custom,
.modal__close, .checkbox__decor:after, .btn, .faq__link path, .logout__link path,
.lk__nav-it path, .lk__nav-it rect, .lk__nav-it circle, .file__btn,
.checkbox__decor:before, .checkbox__decor, .not__it:after, .faq__it-title,
.faq__it-icon path, .select__custom-dropdown, .icon__toggle, .attach__btn,
.prizes__it--email, .mode__toggle:before, .mode__it{
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.footer, .mobile__nav, .nav__box{
  -webkit-transition: all 0.6s ease;
  -moz-transition: all 0.6s ease;
  -ms-transition: all 0.6s ease;
  -o-transition: all 0.6s ease;
  transition: all 0.6s ease;
}

.prizes__it-card, .prizes__it-card img {
  -webkit-transition: all 0.75s ease;
  -moz-transition: all 0.75s ease;
  -ms-transition: all 0.75s ease;
  -o-transition: all 0.75s ease;
  transition: all 0.75s ease;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-5rem);
  }
  100% {
    transform: translatey(0px);
  }
}

@media screen and (min-width: 1024px){
  html {
    font-size: 10px;
  }
}

@media screen and (min-width: 1024px) {
  .hidden-sm-up {
    display: none !important;
  }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  html {
    font-size: 2.6vw;
  }

  body:before {
    height: 39.4rem;
    top: -4rem;
  }
}

@media screen and (max-width: 767px) {
  html {
    font-size: 3.125vw;
  }
}

@media screen and (max-width: 1023px) {
  .hidden-sm-down {
    display: none !important;
  }

  .sc__lk,
  .sc__docs,
  .sc__rating,
  .sc__stat,
  .sc__faq,
  .sc__prizes,
  .sc__notifications{
    .container {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }

  .sc__docs,
  .sc__rating,
  .sc__stat,
  .sc__faq,
  .sc__prizes,
  .sc__notifications{
    .container {
      padding-top: 0.6rem;
    }
  }

  .form__col {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .rating__box-table {
    overflow-x: auto;

    &-scroll {
      width: 118rem;
    }
  }

  .sc__stat {
    .sc__box-title {
      margin-bottom: 1.6rem;
    }
  }

  .sc__stat,
  .sc__rating,
  .sc__faq,
  .sc__prizes{
    position: relative;

    &:before{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -5.6rem;
      background: url("../img/bg-sm-2.svg") no-repeat center top;
      background-size: contain;
      height: 49.1rem;
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }

  .sc__faq {
    .sc__box-title {
      margin-bottom: 2.8rem;
    }
  }

  .prizes__it--prize:before {
    background-color: rgba(232, 217, 199, 0.5);
    position: absolute;
    content: '';
    left: 100%;
    top: 0;
    bottom: -1px;
    width: 5rem;
  }

  .sc__notifications {
    position: relative;

    .sc__box-title {
      margin-bottom: 1rem;
    }

    &:before{
      content: '';
      background: rgb(255,255,255);
      background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(238,231,225,1) 50%, rgba(238,231,225,1) 100%);
      background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(238,231,225,1) 50%,rgba(238,231,225,1) 100%);
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 50%,rgba(238,231,225,1) 50%,rgba(238,231,225,1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#eee7e1',GradientType=0 );
      position: absolute;
      top: -5.6rem;
      left: 0;
      right: 0;
      height: calc(100vh + 5.6rem);
    }

    &:after{
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: -5.6rem;
      background: url("../img/bg-sm-3.svg") no-repeat center top;
      background-size: contain;
      height: 53rem;
    }

    .container {
      position: relative;
      z-index: 2;
    }
  }
}
